<template>
  <div>   
    <v-container grid-list-xl> 
        <v-layout align-center="" justify-center="">
            <v-col sm="12" cols="12">
                <br>
                <v-card > 
                    <mbs-page-tab 
                        :items="taps"/> 
                    <mbs-item-manager
                        :top_label="false"
                        :name_items="NAME_ITEMS"
                        :name_item="NAME_ITEM"
                        :value_items="VALUE_ITEMS"
                        :value_item="VALUE_ITEM"
                        :field_items="FIELD_ITEMS"
                        :header_items="HEADER_ITEMS"
                        :header_items2="HEADER_ITEMS2"
                        :data_items="DATA_ITEMS"
                        :select_items="SELECT_ITEMS" 
                    />
                </v-card>
            </v-col>  
        </v-layout>
    </v-container>  
  </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    let PAGE_NAME = DATA.ITEMS.MEASUREMENT_UNITS.values  
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.MEASUREMENT_UNITS.names,
                NAME_ITEM:DATA.ITEMS.MEASUREMENT_UNITS.name,
                VALUE_ITEMS:DATA.ITEMS.MEASUREMENT_UNITS.values,
                VALUE_ITEM:DATA.ITEMS.MEASUREMENT_UNITS.value, 

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:6}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:6}, 
                    {type:"select",name:"description",value:"region_code",label:"Select Region",required:true,sm:12,md:12,select:{
                        name:'regions',
                        value:'code',
                        text:'name'
                    }}, 
                ],
                HEADER_ITEMS:[ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},   
                    {id:0,name:"region",align:"center",value:'region_name',show:true},   
                    {id:0,name:"region code",align:"center",value:'region_code',show:false},   
                    {id:0,name:"create",value:'created_at',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},  
                    {id:0,name:"action",align:"right",show:true}, 
                ],
                HEADER_ITEMS2:[ 
                    {id:1,name:"General",title:true},
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"region",value:'name',show:true},   
                    {id:0,name:"region code",value:'region_code',show:false},   
                    {id:0,name:"code",show:true},   
                    {id:0,name:"action",show:true}, 
                ],
                taps:[
                    {name:'Company Types',link:"/cpanel/system-setup/company-types"},
                    {name:'Company Size',link:"/cpanel/system-setup/company-sizes"},
                    {name:'Measurement Units',link:"/cpanel/system-setup/measurement-units"},
                ]  
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            ACCESS_ITEM(){
                return permission=>{
                    return  true
                    if (permission === "read") {
                        return this.MBS.actions.ACCESS_READ(this.VALUE_ITEMS) 
                    }else if (permission === "write") {
                        return this.MBS.actions.ACCESS_WRITE(this.VALUE_ITEMS) 
                    }else if (permission === "edit") {
                        return this.MBS.actions.ACCESS_EDIT(this.VALUE_ITEMS) 
                    }else if (permission === "delete") {
                        return this.MBS.actions.ACCESS_DELETE(this.VALUE_ITEMS) 
                    }else{
                        return false
                    }
                }
            },
            
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            }, 
            DATA_ITEMS(){
                return this.$store.getters.getDistricts
            },  
            REGION_ITEMS(){
                return this.$store.getters.getRegions
            },  
            SELECT_ITEMS(){ 
                let item = this.REGION_ITEMS 
                return {
                    regions:item
                }
            },  
             
        },
        methods: {  
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    // setTimeout(() => {
                                    //     this.input.loading = true 
                                    //     this.$store.dispatch("fi_add",{
                                    //         action:this.VALUE_ITEM, 
                                    //         data:action.data,
                                    //         us:this.us
                                    //     })
                                    // }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        } 
                        
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                             
                            if (gettingItems) {
                                this.PAGE_LOAD =false
                            }else{
                                this.PAGE_LOAD =true
                            } 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>